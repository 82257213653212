body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden auto;
}

body, * {
  font-family: 'Poppins'!important;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

pre {
  border-radius: 4px;
  background-color: #191c27;
  padding: 16px;
  font-size: 14px;
  margin-bottom: 24px
}

code[class*="language-"],
pre[class*="language-"] {
  color: rgb(191, 199, 213);
  font-family: Inconsolata, Monaco, Consolas, 'Courier New', Courier, monospace;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
}

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  margin: .5em 0;
  overflow: auto;
  border-radius: 0.3em;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #1d1f21;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: .1em;
  border-radius: .3em;
}

.token.prolog {
  color: rgb(0, 0, 128);
}

.token.parameter {
  color: rgb(255, 255, 255);
}

.token.comment {
  color: rgb(106, 153, 85);
}

.token.doctype {
  color: rgb(191, 199, 213);
}

.token.cdata {
  color: rgb(191, 199, 213);
}

.token.punctuation {
  color: rgb(136, 198, 190);
}

.token.property {
  color: rgb(252, 146, 158);
}

.token.tag {
  color: rgb(252, 146, 158);
}

.token.class-name {
  color: rgb(250, 200, 99);
}

.token.constant {
  color: rgb(100, 102, 149);
}

.token.symbol {
  color: rgb(141, 200, 145);
}

.token.deleted {
  color: rgb(141, 200, 145);
}

.token.number {
  color: rgb(181, 206, 168);
}

.token.inserted {
  color: rgb(181, 206, 168);
}

.token.selector {
  color: rgb(215, 186, 125);
}

.token.char {
  color: rgb(209, 105, 105);
}

.token.builtin {
  color: rgb(197, 165, 197);
}

.token.changed {
  color: rgb(197, 165, 197);
}

.token.keyword {
  color: rgb(197, 165, 197);
}

.token.string{
  color: rgb(195, 232, 141);
}

.token.attr-name {
  color: rgb(156, 220, 254);
}

.token.variable {
  color: rgb(156, 220, 254);
}

.token.operator {
  color: #EDEDED;
}

.token.entity {
  color: #FFFFB6;
  cursor: help;
}

.token.url {
  color: #96CBFE;
}

.language-css .token.string,
.style .token.string {
  color: #87C38A;
}

.token.atrule,
.token.attr-value {
  color: #F9EE98;
}

.token.function {
  color: rgb(121, 182, 242)
}

.token.regex {
  color: #E9C062;
}

.token.important {
  color: #fd971f;
}

.token.important,
.token.bold {
  font-weight: bold;
}

.token.italic {
  font-style: italic;
}

td {
  padding-right: 0px!important;
  padding-top: 0px!important;
  padding-bottom: 0px!important;
}

th {
  padding-right: 0px!important;
}

.MuiOutlinedInput-root {
  height: 36px;
}

.MuiInputLabel-outlined {
  top: -6px!important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  top: 0px!important;
}

/* thead tr th {font-size: 14px!important;} */

/* tbody tr td {font-size: 13px!important;} */
/* tbody tr td a {font-size: 13px!important;} */
/* tbody tr td h6 {font-size: 13px!important;} */

.MuiOutlinedInput-input {
  padding: 12px!important;
}

.MuiSelect-select:focus {
  background-color: transparent!important;
}

.MuiFormLabel-root {
  font-weight: 500!important;
  color: rgba(51, 77, 110, 0.5)!important;
  font-size: 13px!important;
  line-height: 20px!important;
}

.MuiFormLabel-root.Mui-focused {
  color: #3E8AFF!important;
}

.MuiAutocomplete-listbox {
  padding: 6px 0px 7px 0px !important;
  border: 2px solid rgba(194, 207, 224, 0.6);
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.MuiAutocomplete-option {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #35393D;
  padding: 5px 14px 5px 18px!important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0px 44px 0px 0px!important;
}

.MuiTab-root {
  font-size: 14px!important;
  font-weight: 500;
  line-height: 21px!important;
  color: rgba(25, 42, 62, 0.8)!important;
  text-transform: none!important;
  min-width: 110px!important;
  max-width: 110px!important;
}

.MuiTab-root.Mui-selected {
  color: #3E8AFF!important;
  font-weight: 600!important;
}

.MuiTabs-indicator {
  background-color: #3E8AFF!important;
}

.MuiTab-textColorInherit.Mui-selected {
  font-weight: 600!important;
  color: #3E8AFF!important;
}

.chart-wrapper-card-content {
  padding: 0px !important
}

/* .MuiInputLabel-outlined {
  transform: translate(12px, 12px) scale(1);
} */

/* .MuiInputlabel-shrink {
  transform: translate(12px, -6px) scale(.75)!important;
} */

.MuiFormHelperText-root {
  font-size: 11px!important;
}

input {
  color: #334D6E!important;
  font-size: 13px!important;
  line-height: 20px!important;
}

legend {
  font-size: 0.6rem!important;
}

p {
  letter-spacing: 0em!important;
}

/* .MuiOutlinedInput-notchedOutline {
  border-color: rgba(194, 207, 224, 0.6)!important;
} */

.Mui-focused {
  border-color: #3E8AFF!important;
}

.exported-wrapper .smooth-dnd-drop-preview-constant-class {
  background-color: rgba(225, 228, 232, 0.7);
  box-shadow: 3px 3px 10px 3px rgba(0,0,0,0.0);
}

.toreview-wrapper .smooth-dnd-drop-preview-constant-class {
  background-color: rgba(62, 138, 255, 0.08);
  box-shadow: 3px 3px 10px 3px rgba(0,0,0,0.0);
}

.toexport-wrapper .smooth-dnd-drop-preview-constant-class {
  background-color: rgba(46, 212, 122, 0.08);
  box-shadow: 3px 3px 10px 3px rgba(0,0,0,0.0);
}

.tovalidate-wrapper .smooth-dnd-drop-preview-constant-class {
  background-color: rgba(255, 185, 70, 0.08);
  box-shadow: 3px 3px 10px 3px rgba(0,0,0,0.0);
}

.errored-wrapper .smooth-dnd-drop-preview-constant-class {
  background-color: rgba(235, 87, 87, 0.08);
  box-shadow: 3px 3px 10px 3px rgba(0,0,0,0.0);
}

.dd-overlay-disabled {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.04)!important;
}

.dd-overlay-enabled {
  width: 100%;
}

.search-bar fieldset {
  border-color: rgba(194, 207, 224, 1)!important;
}

.edit-invoice-line fieldset {
  border-width: 0px;
}

.edit-invoice-line .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 0px;
}

.edit-invoice-line input {
  padding: 10px 12px !important;
}

.edit-invoice-line-num fieldset {
  border-width: 0px;
}

.edit-invoice-line-num .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 0px;
}

.edit-invoice-line-num input {
  padding: 10px 0px !important;
  width: 32px !important;
  text-align: center;
}

.MuiOutlinedInput-multiline {
  height: 120px;
  color: #334D6E;
  font-size: 14px;
}

.MuiOutlinedInput-multiline textarea {
  color: #334D6E;
  font-size: 14px;
}

.announcekit-widget-badge {
  width: 12px!important;
  top: -8px;
  left: -23px;
}

.btn-reject {
  color: #fff !important;
  background-color: #F7685B !important;
  border-color: #F7685B !important;
  text-transform: none !important;
  margin-left: 10px !important;
  width: 108px !important;
  height: 42px !important;
}

.btn-reject:hover, .btn-reject:focus, .btn-reject:active {
  color: #fff !important;
  background-color: #890E03 !important;
  border-color: #890E03 !important;
  text-transform: none !important;
  margin-left: 10px !important;
  width: 108px !important;
  height: 42px !important;
}

.btn-reject:disabled {
  color: #fff !important;
  background-color: #F3BFBA !important;
  border-color: #F3BFBA !important;
  text-transform: none !important;
  margin-left: 10px !important;
  width: 108px !important;
  height: 42px !important;
}

.btn-tertiary {
  color: #fff !important;
  background-color: #FBA102 !important;
  border-color: #FBA102 !important;
  text-transform: none !important;
  margin-left: 10px !important;
  width: 155px !important;
  height: 42px !important;
}

.btn-tertiary:hover {
  color: #fff !important;
  background-color: #Af7208 !important;
  border-color: #Af7208 !important;
  text-transform: none !important;
  margin-left: 10px !important;
  width: 155px !important;
  height: 42px !important;
}

.btn-tertiary:disabled {
  color: #fff !important;
  background-color: #F7D18E !important;
  border-color: #F7D18E !important;
  text-transform: none !important;
  margin-left: 10px !important;
  width: 155px !important;
  height: 42px !important;
}

.MuiToolbar-root {
  min-height: 60px !important;
  padding-left: 24px;
  padding-right: 24px;
}


.MuiLinearProgress-bar {
  background-color: #03a9f4 !important;
}

.MuiLinearProgress-root {
  background-color: rgb(3, 169, 244);
}

.MuiButton-root.Mui-disabled {
  opacity: 0.5;
}

.MuiButtonGroup-grouped {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.MuiMenuItem-root.Mui-selected {
  background-color: rgba(0, 0, 0, 0.08) !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #03a9f4!important;
}

.MuiTablePagination-root {
  display: 'flex';
  flex: 1;
  justify-content: 'flex-end';
}

.MuiTablePagination-toolbar {
  padding-right: 15px;
}

.MuiTablePagination-selectLabel {
  font-size: 13px !important;
  color: rgba(51, 77, 110, 0.5) !important
}

.MuiTablePagination-selectRoot {
  font-size: 13px !important;
  color: rgba(51, 77, 110, 0.5) !important
}

.MuiTablePagination-select {
  font-size: 13px !important;
  color: #334D6E !important
}

.MuiTablePagination-displayedRows {
  font-size: 13px !important;
  color: rgba(51, 77, 110, 0.5) !important
}

.MuiTablePagination-menuItem {
  font-size: 13px !important;
  color: #334D6E !important
}

.MuiTablePagination-actions {
  margin-left: 0px;
}

.MuiInputBase-root {
  color: #334D6E !important;
  font-size: 13px !important;
  line-height: 20px !important;
}

.MuiFormControlLabel-label {
  font-size: 14px !important;
  color: #334D6E !important;
}

.Mui-checked {
  color: #3E8AFF !important;
}

.MuiSlider-root {
  color: #03a9f4 !important;
}

.MuiSlider-rail {
  height: 16px !important;
  border-radius: 16px !important;
  margin-top: 4px !important;
}

.MuiSlider-track {
  height: 16px !important;
  border-radius: 16px !important;
  margin-top: 4px !important;
}

.MuiSlider-thumb {
  height: 36px !important;
  width: 36px !important;
  border: 3px solid white !important;
  margin-top: 4px !important;
  margin-left: 8px !important;
}

.MuiSlider-mark {
  height: 6px !important;
  width: 6px !important;
  border-radius: 6px !important;
  top: 18px !important;
  color: white;
  margin-top: 0px !important;
}

.no-border {
  border: none !important;
}

.MuiCheckbox-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

.MuiFormControlLabel-label.Mui-disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}

.edit-invoice-line-reorder-icon {
  padding-right: 0px!important;
  padding-top: 4px !important;
  padding-bottom: 0px!important;
  border-bottom: none !important;
}

.edit-invoice-line-body fieldset {
  border-width: 0px;
}

.edit-invoice-line-body .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 0px;
}

.edit-invoice-line-body {
  padding: 0px 2px !important;
  border-bottom: none !important;
  border-image: linear-gradient(transparent 35%, #4C4E641F 35% 65%, transparent 65%) 0 1 0 0 / 2px;
}

.edit-invoice-line-body-end {
  padding: 0px 2px !important;
  border-bottom: none !important;
}

.edit-invoice-line-header {
  border-image: linear-gradient(transparent 35%, #4C4E641F 35% 65%, transparent 65%) 0 1 0 0 / 2px;
}

.edit-invoice-line-line fieldset {
  border-width: 0px;
}

.edit-invoice-line-line .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 0px;
}

.edit-invoice-line-line {
  border-bottom: none !important;
}

.edit-invoice-line-action fieldset {
  border-width: 0px;
}

.edit-invoice-line-action .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 0px;
}

.edit-invoice-line-action {
  border-bottom: none !important;
  padding-top: 0px !important;
}

.dashed-grid-paper {
  --grid-size: 30px;
  --grid-strength: 1px;
  --grid-dash: 2px;
  --grid-gap: 5px;
  --grid-color: #dfdfe3;
  --paper-color: #f1f1f3;

  background-color: var(--paper-color);
  background-size: var(--grid-gap) var(--grid-gap), var(--grid-size) var(--grid-size);
  background-image:
    linear-gradient(to bottom, transparent var(--grid-dash), var(--paper-color) var(--grid-dash)),
    linear-gradient(to right, var(--grid-color) var(--grid-strength), transparent var(--grid-strength)),
    linear-gradient(to right, transparent var(--grid-dash), var(--paper-color) var(--grid-dash)),
    linear-gradient(to bottom, var(--grid-color) var(--grid-strength), transparent var(--grid-strength));
}

.pdf-actions-input {
  color: #ffffff !important;
  border: none;
  width: 32px;
  background-color: #777F8D !important;
  border-radius: 2px;
  text-align: center;
}

/* Added to fix global styling issues */
.css-nqo9i3-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
  background-color: #fff;
  overflow: hidden;
}

.dashboard-chart-wrapper{
  display:flex;
  width:100%;
  height:250px;
  min-height:250px;
  max-height:350px;
  justify-content:center;
  align-items:center;
}
.dashboard-chart-wrapper > div{
  width:100% !important;
  height:100% !important;
}
.dashboard-chart-wrapper > div > div{
  width:100% !important;
  height:100% !important;
}
.dashboard-chart-wrapper > div > div > svg{
  width:100% !important;
  height:100% !important;
  position: relative;
  left:-25px;
  top:20px;
}

.MuiTable-root {
  border-collapse: separate !important;
}

.hide-scroll {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.hide-scroll::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
  width:0;
}

.show-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
}

.show-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

/*It doesn't allow scrolling in any direction*/
.no-scroll{
  overflow: hidden;
}
/*Styling scroll appearance in y direction*/
.y-scroll{
  overflow: clip auto;
}
.y-scroll::-webkit-scrollbar{
  width:6px !important;
}
.y-scroll::-webkit-scrollbar-thumb{
  background-color:#4C4E6499;
  border-radius:10px;
}
.y-scroll::-webkit-scrollbar-thumb:hover{
  background-color:#4C4E645F;
}
.y-scroll::-webkit-scrollbar-track{
  background-color:#fefefe;
}
/*Styling scroll appearance in x direction*/
.x-scroll{
  overflow:auto clip;
}
.x-scroll::-webkit-scrollbar{
  height:6px;
}
.x-scroll::-webkit-scrollbar-thumb{
  background-color:#4C4E6499;
  border-radius:10px;
}
.x-scroll::-webkit-scrollbar-track{
  background-color:#fefefe;
}
.x-scroll::-webkit-scrollbar-thumb:hover{
  background-color:#4C4E645F;
}
/*It allows scrolling in both direction if possible*/
.xy-scroll{
  overflow: auto;
}
.xy-scroll::-webkit-scrollbar{
  height:6px;
  width:6px;
}
.xy-scroll::-webkit-scrollbar-thumb{
  background-color:#4C4E6499;
  border-radius:10px;
}
.xy-scroll::-webkit-scrollbar-track{
  background-color:#fefefe;
}
.xy-scroll::-webkit-scrollbar-thumb:hover{
  background-color:#4C4E645F;
}

.each-pdf-page{
  margin-bottom: 20px;
  width:100% !important;
}
.pdf-page-wrapper{
  margin-bottom: 100px;
}
.each-pdf-preview{
  margin-bottom: 0px;
}

.activeDoc{
  border:2px solid #3E8AFF !important;
}

.white-text{
  color: #fff !important;
}
.center-text{
  text-align: center;
}

.ak-badge>span {
  top: 10px !important;
  right: 8px !important;
  background-color: rgb(62, 138, 255) !important;
  font-size: 10px !important;
}

.notistack-SnackbarContainer{
  bottom: 56px !important
}

.MuiAvatar-root {
  background-color: #E2EDFF !important;
  color: #3E8AFF !important;
}

.MuiAvatar-fallback {
  width: 40% !important;
  height: 40% !important;
}
.product-td{
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.css-eqpfi5-MuiAccordionSummary-content.Mui-expanded{
  margin:12px 0 !important;
} 
.twinfield-accordion  > div:first-of-type{
  flex-direction: row-reverse;
  gap:10px;
}

.custom-field-border > div{
  border:2px solid #C2CFE099;
}
